import { useEffect, useState } from "react";
import { IFixedDownloadButtonProps, IFloatingDownloadButtonComponentProps } from "../../../lib/types/components";
import { FIXED_DOWNLOAD_BUTTON_TYPE, GET_APP_FB_URL } from "../../../lib/utils/constants/GlobalConstants";
import { currentPageURL, isMobile } from "../../../lib/utils/constants/PagesConstants";
import styledComponent from '../../../styles/global';
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";

const FloatingDownloadButtonComponentV2 = (props: IFloatingDownloadButtonComponentProps) => {

    const {
        apkUrl,
        isApkDownloading,
        fixedDownloadButtonData,
        isUtmHotstar,
        blinkTextDownload,
        setIsApkDownloading,
        setIsToastOpen,
        sendAnalytics,
        abTestingData,
        appVersions,
    } = props;

    const isGetAppFb: boolean = currentPageURL === GET_APP_FB_URL;
    const [scrollPositionGame, setScrollPositionGame] = useState<boolean>(false);

    //function to toggle fixed download button popup
    const onScroll = (_event: any): void => {
        if (!isMobile) return;

        if (window.scrollY > 440) {
            setScrollPositionGame(true)
        } else if (window.scrollY <= 440) {
            setScrollPositionGame(false)
        }
    }

    // useEffect to call initial function when page is mounted on DOM
    useEffect(() => {

      window.addEventListener("scroll", onScroll);
        return (): void => {
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        apkUrl: apkUrl,
        isApkDownloading: isApkDownloading,
        type: FIXED_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        isUtmHotstar: isUtmHotstar,
        setIsApkDownloading: setIsApkDownloading,
        setIsToastOpen: setIsToastOpen,
        sendAnalytics: sendAnalytics,
        appVersions,
        abTestingData,
    }

    return (
        !isGetAppFb && blinkTextDownload ?
            <div
                    className={"floadtingDownloadButtonContainer"}
                    style={
                        scrollPositionGame
                            ? {bottom: "0px"}
                            : {bottom: "-180px"}
                    }
                >
                    <style jsx scoped>{
                        styledComponent
                    }
                    </style>
                    <style jsx>{`
                      .floadtingDownloadButtonContainer {
                        position: fixed;
                        width: 100%;
                        z-index: 999;
                        padding-top: 30px;
                        bottom: 0;
                        -webkit-transition: .3s;
                        transition: .3s;
                        background: #fff;
                        -webkit-box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
                        box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
                        padding: 10px 20px;
                      }

                      .floatingDownloadButtonText {
                        background-image: linear-gradient(to top, #62258a 67%, #b3097f 11%);
                        font-family: var(--font-nunito), sans-serif;
                        font-size: 18px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: italic;
                        line-height: normal;
                        letter-spacing: -0.22px;
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        text-transform: uppercase;
                        text-align: center;
                        animation: blinking 1s linear infinite alternate-reverse;
                      }

                      @keyframes blinking {
                        0% {
                          opacity: 1;
                        }

                        100% {
                          opacity: 0.3;
                        }
                      }

                      @media screen and (min-width: 767px) {
                        .floadtingDownloadButtonContainer {
                          display: none !important;
                        }
                      }
                    `}
                    </style>
                    {
                        <p className={"floatingDownloadButtonText"}>{blinkTextDownload}</p>
                    }
                    <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
                </div>
            : <></>
    )
}

export default FloatingDownloadButtonComponentV2;