import { ICommonWithdrawalPartnerComponentProps } from "../../../lib/types/components";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import {currentPageURL} from "../../../lib/utils/constants/PagesConstants";
import { GET_APP_FACEBOOK_URL, GET_APP_FB_URL } from "../../../lib/utils/constants/GlobalConstants";

const CommonWithdrawalPartnerComponentV2 = (props: ICommonWithdrawalPartnerComponentProps) => {

    const {withdrawalPartnerData, isInBanner } = props;
    const isGetAppFacebook: boolean = (currentPageURL === GET_APP_FACEBOOK_URL) || (currentPageURL === GET_APP_FB_URL);

    return (
        withdrawalPartnerData ?
            <div className="withdrawalContainer col">
                <style jsx>
                    {`
                      .withdrawalContainer {
                        display: ${isInBanner ? 'flex' : 'none'};
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        font-family: ${isGetAppFacebook?`var(--font-saira)`:'var(--font-primary)'};
                        font-style: ${isGetAppFacebook?"italic":'normal'};
                        letter-spacing: ${isGetAppFacebook?'1px':'normal'};
                      }

                      .withdrawalContainer > p {
                        font-size: ${isGetAppFacebook?dynamicCssSize(25, 17):dynamicCssSize(22, 10)};
                        font-weight: ${isGetAppFacebook?900:600};
                        color: #fff;
                        margin-bottom: 5px;
                      }

                      .withdrawalContainer > div {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        height: 25px;
                      }
                    `}
                </style>

                <p>{withdrawalPartnerData.text1}</p>
                <div>
                    <CustomImageComponent
                        layout={'fill'}
                        src={withdrawalPartnerData.bannerImg.srcV2}
                        objectFit={"contain"}
                        alt={withdrawalPartnerData.bannerImg.alt || "withdrawal Partner"}
                        priority={true}
                    />
                </div>
            </div>
            :
            <></>
    )
}

export default CommonWithdrawalPartnerComponentV2;