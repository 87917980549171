import {useEffect, useRef, useState} from "react";
import {IFixedDownloadButtonProps, IFloatingDownloadButtonComponentProps,} from "../../../lib/types/components";
import {
    APK_DOWNLOAD_AGAIN_DELAY,
    FIXED_DOWNLOAD_BUTTON_TYPE,
    GET_APP_FB_URL,
} from "../../../lib/utils/constants/GlobalConstants";
import {
    currentPageURL,
    isiOSDevice,
    isMobile, setIsHowToVideoAvailable,
} from "../../../lib/utils/constants/PagesConstants";
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import useIsIOS from "../../../lib/utils/helpers/useIsIOS";
import {DownloadButtonState, useDownloadContext} from "../../../lib/context/CommonContext";
import Analytics from "../../../lib/analytics/Analytics";
import {HowToVideoClose, HowToVideoPlayback, MuteHowToVideo} from "../../../lib/analytics/AnalyticsEvents";

const FloatingDownloadButtonComponentWithVideo = (
    props: IFloatingDownloadButtonComponentProps
) => {
    const {
        apkUrl,
        isApkDownloading,
        fixedDownloadButtonData,
        isUtmHotstar,
        blinkTextDownload,
        blinkTextDownload2,
        setIsApkDownloading,
        setIsToastOpen,
        sendAnalytics,
        isHomePage,
        abTestingData,
        appVersions,
        setAppsFlyerURL,
        executeTesting,
        variant,
        pageName,
        locale,
        isMute = false
    } = props;

    const videoTag = useRef<HTMLVideoElement>(null)
    let isIos = useIsIOS()
    const isGetAppFb: boolean = currentPageURL === GET_APP_FB_URL;
    const [scrollPositionGame, setScrollPositionGame] = useState<boolean>(false);
    const [loadVideo, setLoadVideo] = useState<boolean>(false)
    const [showVideo, setShowVideo] = useState<boolean | null>(null);
    const [mute, setMute] = useState<boolean>(isMute)
    const {downloadButtonState, setDownloadButtonState} = useDownloadContext()
    const toggleOverflowProperty = () => {
        const html: HTMLHtmlElement | null = document.querySelector("html");
        if (html !== null) {
            html.style.overflowY = showVideo && !isiOSDevice ? "hidden" : "auto";
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined = undefined
        // if (isGetAppFacebook) {
        if (downloadButtonState === DownloadButtonState.DownloadClicked) {
            showVideo === null && setShowVideo(true);
            setScrollPositionGame(true)
        } else if (downloadButtonState === DownloadButtonState.Downloading) {
            if (!showVideo && showVideo === null) {
                setShowVideo(true);
            }

            timer = setTimeout(() => {
                setIsApkDownloading(false);
                setDownloadButtonState(DownloadButtonState.DownloadAgain)
            }, APK_DOWNLOAD_AGAIN_DELAY);
        }
        /*} else {
            if (downloadButtonState === DownloadButtonState.DownloadClicked || downloadButtonState === DownloadButtonState.Downloading) {
                setShowVideo(true);
                setScrollPositionGame(true)
                timer = setTimeout(() => {
                    setIsApkDownloading(false);
                    setDownloadButtonState(DownloadButtonState.Initial)
                }, 10000);
            }
        }*/
        return () => clearTimeout(timer);
    }, [downloadButtonState]);

    const onScroll = (_event: any): void => {
        if (!isMobile) return;

        if (window.scrollY > 440) {
            setScrollPositionGame(true);
        } else if (window.scrollY <= 440) {
            setScrollPositionGame(false);
        }
    };

    // useEffect to call initial function when page is mounted on DOM
    useEffect(() => {
        setIsHowToVideoAvailable(true)
        setLoadVideo(true)
        window.addEventListener("scroll", onScroll);
        return (): void => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        isMobile && toggleOverflowProperty();
        if (showVideo && isIos === false) {
            const html: HTMLHtmlElement | null = document.querySelector("html");
            if (html !== null) {
                html.style.scrollBehavior = "initial";
                isMobile && window.scrollTo({top: 450, left: 0});
            }
            if (videoTag.current) {
                videoTag.current.currentTime = 0;
                videoTag.current.play();
            }
        } else {
            videoTag.current?.pause();
        }
        return () => {
            videoTag.current?.pause();
            const html: HTMLHtmlElement | null = document.querySelector("html");
            if (html !== null) {
                html.style.overflowY = "auto";
            }
        };
    }, [showVideo, videoTag.current]);

    const handleVideoMute = (isMute: boolean) => {
        setMute(isMute)
        Analytics.getInstance().logEvent(MuteHowToVideo, {
            mute: isMute
        })
    }

    const handleVideoPlayback = (event) => {
        event.preventDefault()
        let isVideoPlay = false
        if (videoTag?.current?.paused) {
            videoTag?.current?.play()
            isVideoPlay = true
        } else {
            videoTag?.current?.pause()
        }
        if (mute) {
            setMute(false)
        }
        Analytics.getInstance().logEvent(HowToVideoPlayback, {
            isPlay: isVideoPlay
        })
    }

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        apkUrl: apkUrl,
        isApkDownloading: isApkDownloading,
        type: FIXED_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        isUtmHotstar: isUtmHotstar,
        setIsApkDownloading: setIsApkDownloading,
        setIsToastOpen: setIsToastOpen,
        sendAnalytics: sendAnalytics,
        abTestingData,
        appVersions
    };

    return !isGetAppFb && blinkTextDownload ? (
        <div
            className={"floadtingDownloadButtonContainer"}
            style={scrollPositionGame || showVideo ? {bottom: "0px"} : {bottom: "-180px"}}
        >
            <style jsx>
                {`
                  .floadtingDownloadButtonContainer {
                    position: fixed;
                    width: 100%;
                    z-index: 999;
                    bottom: 0;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                    background: #fff;
                    -webkit-box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
                    box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
                    padding: 10px 20px;
                  }

                  .floatingDownloadButtonText {
                    background-image: linear-gradient(to top,
                    #62258a 67%,
                    #b3097f 11%);
                    font-family: var(--font-nunito), sans-serif;
                    font-size: 18px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: italic;
                    line-height: normal;
                    letter-spacing: -0.22px;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-transform: uppercase;
                    text-align: center;
                    animation: blinking 1s linear infinite alternate-reverse;
                    margin-bottom: 10px;
                  }

                  @keyframes blinking {
                    0% {
                      opacity: 1;
                    }

                    100% {
                      opacity: 0.3;
                    }
                  }

                  @media screen and (min-width: 767px) {
                    .floadtingDownloadButtonContainer {
                      display: none !important;
                    }
                  }
                `}
            </style>
            <div
                style={{
                    position: "fixed",
                    top: 50,
                    left: 0,
                    height: "calc(100% - 150px)",
                    width: "100%",
                    backgroundColor: "#fff",
                    display: showVideo && !isiOSDevice ? "block" : "none"
                }}
            >
                <div style={{height: "100%"}}>
                    <div
                        style={{
                            position: "absolute",
                            top: 15,
                            right: 15,
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 20,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            Analytics.getInstance().logEvent(HowToVideoClose)
                            setShowVideo(false);
                            setScrollPositionGame(false)
                        }}
                    >
                        <CustomImageComponent
                            src={
                                "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/close_offer_popup.svg"
                            }
                            width={24}
                            height={24}
                            alt="offer-close-popup"
                        />
                    </div>
                    {loadVideo && <>
                        <video
                            poster={props.howToInstallThumbnail}
                            src={props.howToInstallVideo}
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                objectFit: "cover",
                                objectPosition: 'center'
                            }}
                            preload="auto"
                            loop
                            ref={videoTag}
                            onVolumeChange={(event) => {
                                handleVideoMute(videoTag?.current?.muted || false)
                            }}
                            playsInline
                            muted={mute}
                            controls
                            disablePictureInPicture
                            controlsList="noplaybackrate nodownload nofullscreen"
                            onClick={(event) => {
                                handleVideoPlayback(event)
                            }}
                            onTouchEnd={(event) => {
                                handleVideoPlayback(event)
                            }}
                        />
                    </>}
                    <div
                        style={{
                            position: "absolute",
                            top: 15,
                            left: 15,
                            width: 36,
                            height: 36,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 20,
                            cursor: "pointer",
                            backgroundColor: '#ccc',
                            padding: 2
                        }}
                        onClick={() => setMute(!mute)}
                    >
                        <CustomImageComponent
                            src={
                                mute
                                    ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/mute.webp"
                                    : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/unmute.webp"
                            }
                            width={36}
                            height={36}
                            alt="offer-close-popup"
                        />
                    </div>
                </div>
            </div>
            {isiOSDevice ? (
                <p className={"floatingDownloadButtonText"}>
                    {blinkTextDownload}
                </p>
            ) : (
                <p className={"floatingDownloadButtonText"}>
                    {downloadButtonState === DownloadButtonState.DownloadAgain
                        ? blinkTextDownload2 || "File didn’t download?"
                        : blinkTextDownload}
                </p>
            )}

            <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
        </div>
    ) : (
        <></>
    );
};

export default FloatingDownloadButtonComponentWithVideo;
